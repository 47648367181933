































import { Component, Vue } from 'vue-property-decorator';
import LottiePlayer from 'vue-lottie-player';
import {i18n} from '@/main';
import { getSiteUrl } from '@/utilities/getUrl';
import { EnvProvider } from '@/utilities';
import { getStatic } from '@/utilities/static';

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.congratulation.title'),
    };
  },
  components: {LottiePlayer}
})
export default class RatingThankYou extends Vue {
  public link: string = getSiteUrl(EnvProvider('URL_WEBSITE_LANDLORDS'));
  public getStatic = getStatic;
}
